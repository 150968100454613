:root {
  --blue-gray900: rgba(15, 23, 42, 1);
  --body-XL-font-family: "Roboto", Helvetica;
  --body-XL-font-size: 20px;
  --body-XL-font-style: normal;
  --body-XL-font-weight: 400;
  --body-XL-letter-spacing: 0px;
  --body-XL-line-height: 179.99999523162842%;
  --button-l-font-family: "Roboto", Helvetica;
  --button-l-font-size: 20px;
  --button-l-font-style: normal;
  --button-l-font-weight: 500;
  --button-l-letter-spacing: 0.5px;
  --button-l-line-height: 24px;
  --shadow-dark-m: 0px 0px 4px 0px rgba(0, 0, 0, 0.07), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}
