@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.active{
    font-weight: 700;
    background-color: rgb(23 37 84);
    padding: 10px 16px;
    border-radius: 5px;
    color: white;
    
}
@media (max-width: 768px) {
    #root {
        padding: 1rem;
        /* Add your desired padding for mobile views */
    }
    .hp-top-banner{
        position: relative;
    }
        .m-hp-top-section{
            position: relative;
            z-index: -1;
        }
        .m-display-none{
            display: none;
        }
        .m-text-lh{
            line-height: 4rem;
        
        }

}
#root{
        font-family: 'Poppin', sans-serif;
}
.image-gallery {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    justify-content: center;
    height: auto;
    /* Set the container height as needed */
}

.image {
    max-width: 100%;
    height: auto;
    cursor: pointer;
    opacity: 0.7;
    /* Initial opacity for side images */
    transition: opacity 0.5s ease-in-out;
    /* Transition for opacity */
    scroll-snap-align: start;
    opacity: 0.7;
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.image:hover,
.center {
    opacity: 1;
    /* Hover or center effect to make the image fully visible */
}

.center {
    transform: scale(1.2);
    z-index: 1;
    /* transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; */
    
    /* Increase the size of the center image */
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(-115px); /* Adjust the distance for the desired right offset */
        will-change: opacity, transform;
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-in-right {
    animation: fadeInRight 1.25s ease-out; /* Adjust the duration as needed */
}

.container{
    background-image: url('./assets/brand/Col.png');
    position: relative;
    margin-top: 2rem;
    object-fit: cover;
}
.contact {
    background-image: url('./assets/brand/Rectangle 1.png');

}